<template>
  <div>
    <div class="step-title text-center mx-2 mt-2 mb-2">What would you like to order?</div>
    <v-row class="mx-0 my-0">
      <v-col v-for="(menu, index) in menuList" :key="index" cols="6" sm="6" md="6" class="px-1 py-1 mb-1">
        <div class="item-wrap d-flex flex-column flex-sm-row px-2 py-2" @click="clicked(menu)">
          <div class="item-left text-center w-30" :class="{ 'w-100': isMobileView }">
            <img class="item-img" :src="getItemImg(menu.images[0].url)" alt="item" />
          </div>
          <div class="item-right flex-grow-1 d-flex flex-column justify-sm-center align-center align-sm-start ml-0 ml-sm-4">
            <!-- <div
              v-if="menu.grp == MENU_GRP.ALACARTE && menu.id != 21 /* Beverage */ && menu.id != 90 /* Non Food */"
              class="item-label mb-1"
            >
              {{ $t('menu.lbl.aLaCarte') }}
            </div> -->
            <div class="item-label mb-2">{{ menu.name }}</div>
            <div class="item-subtitle text-center mb-1">{{ menu.subtitle }}</div>
            <!-- <div v-if="!isUndefinedNullOrEmpty(menu.price)" class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(menu.price)}` }}</div> -->
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <CommonModal
      v-if="referralModalShow"
      v-model="referralModalShow"
      :modal-size="'modal-xs'"
      :title="'Baoger Lab Referral'"
      :footer="false"
    >
      <template #body>
        <div class="text-center pa-3">
          <div class="font-1dot25r font-weight-medium mb-3">
            Get your cravings fixed now and enjoy <span class="font-red-common">15% off</span> your first order!
          </div>
          <AppBtn class="btn-black mb-3">{{ $t('orders.act.orderNow') }}</AppBtn>
        </div>
      </template>
    </CommonModal> -->
    <!-- <AvailabilityCheck v-if="preSelectedLocModalShow" /> -->
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { MENU_GRP } from '@/common/enum/menu';
// import AvailabilityCheck from '@/views/AvailabilityCheck';
// import CommonModal from '@/components/Modal';

export default {
  name: 'MenuListAll',
  components: {
    // AvailabilityCheck,
    // CommonModal,
  },
  props: {
    // rt: {
    //   type: String,
    //   default: '',
    // },
  },
  data() {
    return {
      successModal: false,
      selectedMenu: null,
      defaultImg: require('@/assets/logo.png'),
      referralModalShow: false,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
      preSelectedLocModalShow: state => state.store.preSelectedLocModalShow,
    }),
    ...mapGetters(['getPreSelectedChannelFlag', 'getStoreId']),
    MENU_GRP() {
      return MENU_GRP;
    },
  },
  created() {
    this.SET_DRAWER_TITLE('Menu');
    // if (this.rt) {
    //   this.referralModalShow = true;
    // }
    if (this.isUndefinedNullOrEmpty(this.$route.query?.storeId)) {
      if (!this.isUndefinedNullOrEmpty(this.getStoreId)) {
        this.navigateReplace('MenuAll', {}, { storeId: this.getStoreId });
        return;
      }
      // if (this.getPreSelectedChannelFlag != 1) {
      //   this.SET_PRE_SELECTED_LOC_MODAL_SHOW(true);
      // }
    }
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE', 'SET_PRE_SELECTED_LOC_MODAL_SHOW']),
    clicked(menu) {
      switch (menu.grp) {
        case MENU_GRP.SET:
        case MENU_GRP.FIXED_PRICE:
          this.navigatePush('MenuSetItem', { menuId: menu.id }, { step: 1 });
          break;
        case MENU_GRP.ALACARTE:
          this.navigatePush('MenuALaCarteItem', { menuId: menu.id });
          break;
        case MENU_GRP.PROMOTION:
          this.navigatePush('MenuPromotion');
          break;
        default:
          break;
      }
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.item-wrap {
  // border: 4px solid #000;
  background-color: #fff5d3;
  border-radius: 10px;
  // box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  box-shadow: 1px 1px 3px 1px #8e8e8e;
  cursor: pointer;
  height: 100%;
}
.item-img {
  max-width: 130px;
  width: 100%;
  @include for-sm {
    max-width: 110px;
  }
}
.item-text-wrap {
  max-width: 250px;
  @include for-sm {
    max-width: 100%;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 1.3rem;
  font-weight: 500;
  @include for-sm {
    font-size: 1.2rem;
  }
}
.item-subtitle {
  color: var(--grey-dark-1);
  font-size: 0.8rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 1.2rem;
  font-weight: 600;
}
.step-title {
  color: var(--primary-black-color);
  font-size: 1.3rem;
  font-weight: 600;
}
</style>
